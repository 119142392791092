<template>
    <maintenance-page
        page-title="Account"
        page-icon="mdi-folder-outline"
        collection-name="account"
        done-redirect-url="/auth/admin/account?isHistory=true"
    />
</template>
<script>
import MaintenancePage from '../../../components/pages/Maintenance';

export default {
    name: 'AccountDetail',
    components: { MaintenancePage },
};
</script>
